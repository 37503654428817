import "./about.scss";
import darkCircle1 from './assets/dark-circle-1.png'
import darkCircle2 from './assets/dark-circle-2.png'
import lightCircle1 from './assets/light-circle-1.png'
import lightCircle2 from './assets/light-circle-2.png'
import React from "react";
import ArrowIcon from "../../components/arrowIcon/arrowIcon";

export default function About(props){
    const { title, content, style, theme } = props;
    return(
        <div id="about-container" style={style.background}>
            <img className="about-page-ornament1" src={`${theme==='dark'?darkCircle1:lightCircle1}`} alt=""/>
            <img className="about-page-ornament2" src={`${theme==='dark'?darkCircle2:lightCircle2}`} alt=""/>
            <ArrowIcon />
            <div id='about-content' style={style.content}>
                <div id="about-title" style={style.title}>
                    {title}
                </div>
                <div>
                    {
                        content.map((content,index)=>{
                            return <div  key={index} className='lineBetween'>{content}</div>
                        })
                    }
                </div>
            </div>

        </div>
    )
}