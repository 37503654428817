import './App.scss';
import { Routes, Route, BrowserRouter} from "react-router-dom";
import React, {useState} from "react";
import Poster from "./pages/posterPage/PosterConfigPage";
import Claim from "./pages/claimPage/ClaimConfigsPage";
import NftList from "./pages/nftListPage/NftListConfigPage";
import {createContext, useEffect, useReducer} from "react";
import {responseCode, mainTheme, colorTheme} from "./config";
import About from "./pages/aboutPage/AboutConfigPage";
import Congrats from "./pages/congratsPage/CongratsConfigsPage";
import Login from "./pages/loginPage/LoginConfigPage";
import Home from "./pages/homePage1/homePageConfig";
import Detail from './pages/detailPage/DetailConfigPage';
import Policy from './pages/policyPage/PolicyConfig'
import Certification from "./pages/certificationPage/CertificationPageConfig";

const initialState = {
    isLogin: null,
    userId: null,
    accessToken: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("userId", action.payload.userId);
            localStorage.setItem("accessToken", action.payload.accessToken);
            localStorage.setItem('phone', action.payload.phone);
            localStorage.setItem('authenticated', action.payload.authenticated);
            return {
                ...state,
                isLogin: true,
                userId: action.payload.userId,
                accessToken: action.payload.accessToken,
            };
        case "LOGOUT":
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            localStorage.removeItem('phone');
            localStorage.removeItem('authenticated');
            return {
                ...state,
                isLogin: false,
                userId: null,
                accessToken: null,
            };
    }
}

export const portalAddress = process.env.REACT_APP_PORTAL_ADDRESS;
export const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;

export const appId = "wilding";
export const AuthContext = createContext();
export const ThemeCtx = createContext({});

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [theme, setTheme] = useState({
        main: mainTheme,
        color: colorTheme
    });

    useEffect(async() => {
        let userId = localStorage.getItem("userId");
        let accessToken = localStorage.getItem("accessToken")
        if (userId && accessToken) {
            try {
                const response = await fetch( portalAddress + "/user/token", {
                    method: "GET",
                    headers: { accessToken },
                } );
                const json = await response.json();

                if (json.returnCode === responseCode.success) {
                    dispatch({
                        type: "LOGIN",
                        payload: {
                            isLogin: true,
                            userId,
                            accessToken,
                            phone: json.data.phone,
                            authenticated: json.data.authenticated
                        }
                    })
                }else{
                    dispatch({
                        type: "LOGOUT",
                    })
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            dispatch({
                type: "LOGOUT",
            })
        };
    },[])

    window.addEventListener('storage', function (e) {
        localStorage.setItem(e.key, e.oldValue);
    });

  return (
    <div className="App" id='app'>
        <div className='appContainer'>
            <AuthContext.Provider value = {{state, dispatch}}>
                <ThemeCtx.Provider value={{ theme, setTheme }}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/policy" element={<Policy/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/claim" element={<Claim/>}/>
                            <Route path="/list" element={<NftList/>}/>
                            <Route path="/congrats" element={<Congrats/>}/>
                            <Route path="/detail/:id" element={<Detail/>}/>
                            <Route path="/poster/:id" element={<Poster/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/certification" element={<Certification/>}/>
                        </Routes>
                    </BrowserRouter>
                </ThemeCtx.Provider>
            </AuthContext.Provider>
        </div>
    </div>
  );
}

export default App;
