import './home.scss'
import MenuIcon from "../../components/menuIcon/menuIcon";
import Description from "../../components/description/description"
import NftCard from '../../components/nftCard/nftCard';
import FooterWarning from '../../components/footerWarning/footerWarning';
import {useContext, useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../App";
import video from '../../assets/nft/dma-video.mp4';

export default function Home(props) {
  const { theme, style, nftInfo } = props
  const [currentIndex,setIndex] = useState(0);
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSlideChange = (e) => {
    let index = e.realIndex;
    setIndex(index);
  }

  const claim = () => {
    if(state.isLogin){
      navigate('/claim')
    }else{
      navigate('/login');
    }
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div className={`home-page ${theme==='dark'?'home-page-dark-theme':''}`}>
      <MenuIcon/>
      <img className="top-background-img" src={nftInfo[currentIndex].image} alt=""/>
      <div className={`top-container ${theme==='dark'?'top-container-dark-theme':''}`}>
        <div className={`title ${theme==='dark'?'title-dark-theme':''}`}>{nftInfo[currentIndex].header}</div>
        <NftCard nftInfo={nftInfo} handleSlideChange={handleSlideChange}/>
      </div>
      <Description desc={nftInfo[currentIndex].desc}/>
      <button onClick={claim} className='retrieve-button' style={{background:style.buttonBackground}}>立即领取</button>
      <FooterWarning />
      <link rel="preload" as="video" href={video}/>
    </div>
  )
}