import Claim from "./Claim";
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function ClaimConfigsPage(){
    const { theme } = useContext(ThemeCtx);
    const style = {
        buttonBackground: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`
    }
    return(
        <Claim style={style} theme={theme} />
    )
}
