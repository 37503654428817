import './ProgressLoading.scss'

export default function ProgressLoading ({text, isShowing}) {

  return (
    <div className="progress-loading" style={{display: isShowing? 'flex' : 'none'}}>
      <div className='loading-background'></div>
      <div className='loading-content'>
        <div className='loading-icon'></div>
        <div className="loading-text">{text}</div>
      </div>
    </div>
  )
}