import {useContext} from "react";
import {ThemeCtx} from "../../../App";
import AuthModal from "./Modal";

export default function AuthModalConfig(props) {
    const { visible, setVisible } =props;

    const { theme } = useContext(ThemeCtx);

    const styleConfig = {
        buttonBackground: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`
    }

    return(
        <AuthModal theme={theme} style={styleConfig} setVisible={setVisible} visible={visible}/>
    )
}
