import React from 'react';
import NFTlist from "./NFTlist";
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function NftListConfigPage() {
    const { theme } = useContext(ThemeCtx);
    return(
     <div>
         <NFTlist theme={theme.main}/>
     </div>
    )
}
