import { Login } from "./Login";
import backgroundImage from "./assets/background-image.png";
import {loginLogo} from '../../config'
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function LoginConfigPage() {
  const { theme } = useContext(ThemeCtx);
  const logo = loginLogo[theme.main]

  const style = {
    loginButton: {
      //login button 登录按钮
      buttonTextColor: "#FFFFFF",
      buttonBackgroundColor: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`,
      // login button disabled 登录按钮禁用
      buttonTextDisableColor: "#FFFFFF",
      buttonDisableBackgroundColor: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`,
    },
    codeButton: {
      textColor: theme.color,
    },
    userAgreement: {
      descriptionTextColor: `${theme.main==='dark'?'#C7C7C7':'rgba(16, 16, 16, 0.5)'}`,
      userAgreementTextColor: theme.color,
    },
    checkBox: {
      // check box checked color  勾选框勾选颜色
      checked: theme.color,
    },
    loginPage: {
      //login page background color 登录页背景色
      backgroundColor: "#ffffff",
    },
  };

  return (
    <Login
      style={style}
      logo={logo}
      backgroundImage={backgroundImage}
      theme={theme}
    />
  )
}