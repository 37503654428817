import './footerWarning.scss'
import {useContext}  from 'react';
import {ThemeCtx} from "../../App";

export default function FooterWarning () {
  const { theme } = useContext(ThemeCtx);

  return (
    <div className="footer-warning">
      <div className={`warning-text ${theme.main==='dark'?'warning-text-dark-theme':''}`}>
      本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
      </div>
    </div>
  )
}