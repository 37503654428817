import './CertificationPage.scss';
import ArrowIcon from "../../components/arrowIcon/arrowIcon";
import React, {useEffect, useState} from "react";
import {bottomToast} from "../../utils/toast";
import useSMS from "../../utils/useSMS";
import {useGetAuthentication, usePostAuthentication} from "../../utils/useAuth";
import {useNavigate} from "react-router-dom";

export default function CertificationPage(props){
    const {style, theme} = props;

    const [name, setName] = useState();
    const [code, setCode] = useState();
    const [id, setId] = useState();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("获取验证码");
    const [btnClicked, setBtnClicked] = useState(false);
    const [count, setCount] = useState(0);
    const [authenticated,setAuthenticated] = useState(0);
    const [authInformation, setAuthInformation] = useState();

    const phone = localStorage.getItem('phone');
    const accessToken = localStorage.getItem('accessToken');
    const postAuth = usePostAuthentication();
    const getAuth = useGetAuthentication(accessToken);
    const sms = useSMS(phone);
    const navigate = useNavigate();

    const containerStyle={
        background: theme.main === 'dark'? 'black': 'white',
        color:theme.main === 'dark'? 'white': 'dark'
    }

    const startCountdown = () => {
        setBtnDisabled(true);
        setCount(60);
        setBtnClicked(true);
    };

    const endCountdown = () => {
        setBtnText("获取验证码");
        setBtnDisabled(false);
        setCount(0);
    };

    const getSMS = async () => {
            sms().then((res) => {
                if (res) {
                    bottomToast('获取验证码成功');
                    startCountdown();
                } else {
                    bottomToast(res.msg);
                    endCountdown();
                }
            });
    };

    const submitAuth = async() => {
        if(name.length < 2){
            bottomToast('请输入正确的姓名')
        }else if(id.length !== 18){
            bottomToast('请输入正确的身份证号码')
        }else if(code.length !== 6){
            bottomToast('请输入正确的验证码')
        }else{
            const res = await postAuth(code, name, id);
            if (res && res.authenticated) {
                bottomToast("认证成功！");
                getAuthInformation();
                setAuthenticated(1);
            }
        }
    }

    const toClaimPage = () =>{
        navigate('/claim');
    }

    const getAuthInformation = async() => {
        const info = await getAuth();
        setAuthInformation(info);
        setAuthenticated(info.authenticated);
    }

    useEffect(() => {
        if (count > 0) {
            setBtnText(count + "s");
            const timer =  setTimeout(() => {
                if (count > 1) {
                    setCount(count - 1);
                } else {
                    endCountdown();
                }
            }, 1000);
            return ()=>{
                clearTimeout(timer);
            }
        };
    }, [count]);

    useEffect(() => {
        if (accessToken) {
            getAuthInformation();
        }else{
            navigate('/login');
            bottomToast('请先登陆')
        }
    },[accessToken])

   return(
       <>
       {
           authenticated === 1 ?
                   <div className='certificationSuccessContainer' style={containerStyle}>
                       <ArrowIcon/>
                       <div className='nameContainer'>*{authInformation?.name?.substring(1)}</div>
                       <div className='idContainer'>{authInformation?.IDCard && (authInformation.IDCard?.substring(0, 3) + " **** **** **** " + authInformation.IDCard?.substring(18-4,18))}</div>
                       <div className='numberContainer'>
                           <div style={{ width: '80%', display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                               <div className='number'>{authInformation?.phone?.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}</div>
                               <div className='currentAccountTitle'>当前账号</div>
                           </div>
                       </div>
                       <div className='confirmButton' style={{ background: style.buttonBackground}} onClick={toClaimPage}>
                           继续领取
                       </div>
                   </div>
           :
           <div className='certificationPageContainer' style={containerStyle}>
               <ArrowIcon/>
               <div className='title'>实名认证</div>
               <div className='subtitle'>以下信息用于核实您的真实身份</div>
               <div className='inputContainer'>
                   <div>真实姓名</div>
                   <input type='text' placeholder='请填写您本人的真实姓名' onChange={(e)=>{setName(e.target.value)}}/>
                   <div>身份证号</div>
                   <input placeholder='请填写您本人的身份证号' onChange={(e)=>{setId(e.target.value)}}/>
                   <div className='codeContainer'>
                       手机号 +86 {localStorage.getItem('phone')?.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}
                       <input placeholder='输入验证码'  maxLength={6} onChange={(e)=>{setCode(e.target.value)}}/>
                       <button onClick={getSMS} disabled={btnDisabled} className="codeButton" style={{ color: count > 0? containerStyle.color:theme.color }}>
                           {btnText}
                       </button>
                   </div>

                   <div className='warningText'>应法律要求，所有使用数字服务的用户都需要提交实名信息</div>
               </div>
               <div className='confirmButton' style={{background: code && name && id && btnClicked?  style.buttonBackground:'rgba(255, 255, 255, 0.2)'}} onClick={submitAuth}>
                   提交认证
               </div>
           </div>
       }
       </>
   )
}
