import CertificationPage from "./CertificationPage";
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function Certification() {
  const { theme } = useContext(ThemeCtx);

  const styleConfig = {
    buttonBackground: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`
  }

  return(
      <CertificationPage theme={theme} style={styleConfig}/>
  )
}
