import './arrowIcon.scss'
import {useContext} from "react";
import {ThemeCtx} from "../../App";
import arrowLight from "./assets/arrow-light.png"
import arrowDark from "./assets/arrow-dark.png"
import {useNavigate} from "react-router-dom";

export default function ArrowIcon () {
  const navigate = useNavigate();
  const { theme } = useContext(ThemeCtx);
  const navBack = () => {
    navigate(-1)
  }

  return (
    <div className={`arrow-icon-container ${theme.main==='dark'?'arrow-icon-dark':'arrow-icon-light'}`} onClick={navBack}>
      <img src={`${theme.main==='dark'?arrowDark:arrowLight}`} alt='' style={{position:'relative',width:'11px', height:'18px', marginTop:'-7px', marginLeft:'-3px'}}/>
    </div>
  )
}