import {useNavigate} from "react-router";
import "./congrats.scss";
import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import FooterWarning from '../../components/footerWarning/footerWarning';
import Description from "../../components/description/description"
import darkThemeLight from './assets/darktheme-light.png'
import lightThemeLight from './assets/lighttheme-light.png'
import ProgressLoading from '../../components/progressLoading/ProgressLoading';

export default function Congrats(props){
    const { style, theme} =props;
    let navigate = useNavigate();
    let [params] = useSearchParams();

    const confirm = () => {
        navigate('/list');
    }

    const url = params.get("url");
    const name = localStorage.getItem("name")
    const desc = localStorage.getItem("desc")
    const tokenId = params.get("tokenId")

    const [loaded, setLoaded] = useState(false);
    const [img1Loaded, setImg1Loaded] = useState(false);
    const [img2Loaded, setImg2Loaded] = useState(false);

    useEffect(() => {
        if (img1Loaded&&img2Loaded) {
            setTimeout(() => {
                setLoaded(true);
            },2000)
        }
    },[img1Loaded, img2Loaded]);

    function getUrl(url){
        return `${url}!small`
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <div className={`congrats-page ${theme==='dark'?'congrats-page-dark-theme':''}`}>
            <ProgressLoading text={'努力铸造中...'} isShowing={!loaded}/>
            <img className="nft-background-image" src={getUrl(url)} alt="" onLoad={()=>{setImg1Loaded(true)}} mode="widthFix"/>
            <div className={`nft-container ${theme==='dark'?'nft-container-dark-theme':''}`}>
                <img className="nft-background-light" mode="heightFix" src={`${theme==='dark'?darkThemeLight:lightThemeLight}`} alt=""/>
                <div className={`nft-title ${theme==='dark'?'nft-title-dark-theme':''}`}>{name}</div>
                <div className={`nft-id ${theme==='dark'?'nft-id-dark-theme':''}`}># {tokenId}</div>
                <img className="nft-image" src={getUrl(url)} alt="" onLoad={()=>{setImg2Loaded(true)}}/>
            </div>
            <Description desc={desc}/>
            <button className='confirm-button' onClick={confirm} style={{background:style.buttonBackground}}>确定</button>
            <FooterWarning/>
        </div>
    )
}