import './Modal.scss';
import CloseIcon from '../assets/Subtract.png';
import {useNavigate} from "react-router-dom";

export default function AuthModal(props){
    const {visible, setVisible, theme, style} = props;
    const navigate = useNavigate();

    const containerStyle={
        background: theme.main === 'dark'? 'black': 'white',
        color:theme.main === 'dark'? 'white': 'dark'
    }

    const goCertificate = () =>{
        navigate('/certification');
        setVisible(false);
    }

    return (
        <>
            {visible ?
            <div className='authModalContainer'>
                <div className='modal'>
                    <div className='title'>实名认证</div>
                    <div className='line'/>
                    <div className='contentText'>您还没有绑定身份信息</div>
                    <div className='contentText'>为保障你的账户安全请及时绑定</div>
                    <div style={{background: style.buttonBackground}} className='buttonContainer' onClick={goCertificate}>
                        去认证
                    </div>
                    <div className='connectLine'/>
                    <img src={CloseIcon} alt='' className='icon' onClick={() => {setVisible(false)}}/>
                </div>
            </div>
                : null}
        </>
    )
}