import About from "./About";
import {about} from '../../config'
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function AboutConfigPage(){
    const {title, content} = about
    const { theme } = useContext(ThemeCtx);

    return(
        <About title={title} content={content} style={styleConfig[theme.main]} theme={theme.main}/>
    )
}

const styleConfig={
    light: {
        background: {
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.22) 69.95%)'
        },
        title: {
            color: '#101010'
        },
        content: {
            color: 'rgba(16, 16, 16, 0.5)'
        }
    },
    dark: {
        background: {
            background: '#1D1D1D'
        },
        title: {
            color: '#FFFFFF'
        },
        content: {
            color: 'rgba(255, 255, 255, 0.5)'
        }
    },
}




