import Detail from "./Detail";
// import LeftPlant from '../../assets/left-plant.png';
// import RightPlant from '../../assets/right-plant.png';
// import nft from "../../assets/nft.png";
// import CardFrame from "../../assets/card-frame.png";
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function DetailConfigPage(){
    const { theme } = useContext(ThemeCtx);

    const style = {
        buttonBackground: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`,
        addressColor: theme.color
    }

    return(
        <Detail style={style} theme={theme.main}/>
    )
}
