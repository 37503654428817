import Home from './home.js'
import {useContext} from "react";
import {ThemeCtx} from "../../App";
import {homePageNFT} from '../../config'

export default function HomePageConfig () {
  const { theme } = useContext(ThemeCtx);
  const style = {
    buttonBackground: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`
  }
  return (
    <Home theme={theme.main} style={style} nftInfo={homePageNFT}/>
  )
}