import {useContext}  from 'react';
import './description.scss';
import lightLeftQuotation from '../../assets/light-left-quotation-mark.svg';
import lightRightQuotation from '../../assets/light-right-quotation-mark.svg';
import darkLeftQuotation from '../../assets/dark-left-quotation-mark.svg';
import darkRightQuotation from '../../assets/dark-right-quotation-mark.svg';
import {ThemeCtx} from "../../App";

export default function Description(props) {
  const { theme } = useContext(ThemeCtx);
  const {desc} =props;

  return(
       desc && desc.length> 0? <div className="desc-container">
          <div>
              <img src={theme.main==='dark'?darkLeftQuotation:lightLeftQuotation} alt='' style={{ paddingBottom: '50px', paddingRight: '10px' }}/>
          </div>
          <div className={`desc-content ${theme.main==='dark'?'desc-content-dark-theme':''}`}>
              {desc}
          </div>
          <img src={theme.main==='dark'?darkRightQuotation:lightRightQuotation} alt='' style={{ paddingTop: '50px',paddingLeft: '10px' }}/>
      </div>: null
  )
}